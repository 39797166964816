import React, { useCallback, useEffect } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';
import { css } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip/Tooltip';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import BookOpenIcon from '@atlaskit/icon-lab/core/book-open';
import { Box, xcss, Text } from '@atlaskit/primitives';

import { PageSegmentLoadEnd } from '@confluence/browser-metrics';
import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';
import { expValEquals } from '@confluence/feature-experiments';
import { fg } from '@confluence/feature-gating';

import { READ_TIME_METRIC } from './perf.config';

export const i18n = defineMessages({
	abbreviatedReadTime: {
		id: 'read-time.by-line.abbreviated-estimated-read-time',
		defaultMessage: '{readTime} min',
		description: 'Abbreviated estimated time to read this page',
	},
	readTime: {
		id: 'read-time.by-line.estimated-read-time',
		defaultMessage: '{readTime} min read',
		description: 'Estimated time to read this page',
	},
	iconLabel: {
		id: 'read-time.by-line.icon-label',
		defaultMessage: 'Read time',
		description: 'Accessibility label for the book icon next to the read time in the byline',
	},
	tooltipDescription: {
		id: 'read-time.tooltip-description',
		defaultMessage: 'Estimated read time',
		description: 'Tooltip message',
	},
	wordCount: {
		id: 'read-time.word-count',
		defaultMessage: '{wordCount} {wordCount, plural, one {word} other {words}}',
		description:
			'This message informs the user about the total number of words used in their content.',
	},
});

const abbreviatedReadTimeStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	gap: 'space.100',
	width: 'max-content',
});

const badgeStyles = xcss({
	gap: 'space.050',
	height: '24px',
});

const readTimeWithBulletStyles = xcss({
	display: 'inline-flex',
});

const baseStyledReadTime = css({
	marginRight: token('space.050'),
	paddingLeft: token('space.075'),
	color: token('color.text.subtle'),
});

const detailsPanelStyledReadTime = css({
	marginRight: 0,
	color: token('color.text'),
	'&::before': {
		content: '•',
		display: 'inline-block',
		color: token('color.text'),
		paddingRight: token('space.050'),
		paddingLeft: token('space.050'),
		textDecoration: 'none',
	},
});

const badgeStyledReadTime = css({
	marginRight: token('space.0'),

	paddingLeft: token('space.050'),
	paddingRight: token('space.050'),

	cursor: 'pointer',
	height: '24px',
	'&:hover': {
		backgroundColor: token('color.background.neutral.subtle.hovered'),
		borderRadius: token('border.radius'),
	},
});

const readTimeBullet = css({
	'&::before': {
		content: '•',
		display: 'inline-block',
		color: token('color.text.subtle'),
		paddingRight: token('space.100'),
		textDecoration: 'none',
	},
});

type ReadTimeComponentType = {
	pageId: string;
	readTime: number | null;
	isAbbreviatedReadTime?: boolean;
	isInEditor?: boolean;
	isDetailsPanel?: boolean;
	showWordCount?: boolean;
	wordCount?: number | null;
};

export function ReadTimeComponent({
	pageId,
	readTime,
	isAbbreviatedReadTime,
	isInEditor,
	isDetailsPanel,
	wordCount,
	showWordCount,
}: ReadTimeComponentType) {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();

	const useAbbreviatedReadTime =
		isAbbreviatedReadTime ??
		expValEquals('cc-page-experiences-new-renderer-byline', 'cohort', 'test');

	const fireTooltipEvent = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'page',
				action: 'displayed',
				actionSubject: 'tooltip',
				actionSubjectId: 'estimatedReadTime',
				attributes: {
					isInEditor: !!isInEditor,
				},
			},
		}).fire();
	}, [createAnalyticsEvent, isInEditor]);

	useEffect(() => {
		if (readTime) {
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					source: 'confluence-frontend',
					action: 'displayed',
					actionSubject: 'readTime',
					actionSubjectId: pageId,
					attributes: {
						readTime,
						isInEditor: !!isInEditor,
					},
				},
			}).fire();
		}
	}, [pageId, readTime, createAnalyticsEvent, isInEditor]);

	const readTimeMessage = useAbbreviatedReadTime ? i18n.abbreviatedReadTime : i18n.readTime;

	return (
		// styled elements cannot be the primary component as ssr-placeholder attributes
		// will erroneously be added to the <style> components during SSR. To fix this,
		// this component is wrapped in a div with an inline style
		<div
			data-vc="read-time"
			data-testid="read-time-component"
			{...ssrPlaceholderIdProp}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={{ display: 'inline-flex' }}
		>
			{showWordCount && !!wordCount && (
				<Text>
					{formatMessage(i18n.wordCount, {
						wordCount,
					})}
				</Text>
			)}
			{!!readTime && (
				<span
					css={[
						!useAbbreviatedReadTime && readTimeBullet,
						isDetailsPanel ? detailsPanelStyledReadTime : baseStyledReadTime,
						useAbbreviatedReadTime &&
							fg('cc_page_experiences_byline_badge_style') &&
							badgeStyledReadTime,
					]}
				>
					<Tooltip
						tag="span"
						content={<FormattedMessage {...i18n.tooltipDescription} />}
						onShow={fireTooltipEvent}
					>
						<Box
							xcss={[
								useAbbreviatedReadTime ? abbreviatedReadTimeStyles : readTimeWithBulletStyles,
								useAbbreviatedReadTime &&
									fg('cc_page_experiences_byline_badge_style') &&
									badgeStyles,
							]}
						>
							{useAbbreviatedReadTime && <BookOpenIcon label={formatMessage(i18n.iconLabel)} />}
							<FormattedMessage {...readTimeMessage} values={{ readTime }} />
						</Box>
					</Tooltip>
				</span>
			)}
			<PageSegmentLoadEnd metric={READ_TIME_METRIC} />
		</div>
	);
}
